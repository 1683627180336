import {
  ResponsiveValue,
  Sidebar as SidebarComponent,
  SidebarLayout,
  ColorScheme,
} from "@gocardless/flux-react";

import { NAV_WIDTH, NAV_WIDTH_XL } from "../core/constants";

import { Route } from "src/common/routing";
import Navigation from "src/components/navigation/Navigation";

export interface SidebarProps {
  highlightedMenuLink?: Route;
  height?: ResponsiveValue<number | string>;
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { highlightedMenuLink: highlightedRoute, height } = props;

  return (
    <SidebarComponent
      open
      colorScheme={ColorScheme.OnDark}
      layout={SidebarLayout.StaticLeft}
      contentWidth={[NAV_WIDTH, null, null, null, NAV_WIDTH_XL]}
    >
      <Navigation highlightedMenuLink={highlightedRoute} height={height} />
    </SidebarComponent>
  );
};
