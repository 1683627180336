import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  JustifyContent,
  PlainLink,
  Space,
  Text,
  Visibility,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { LinkBuilder, Route } from "../../routing";

import ReferButtonIconHeart from "src/assets/svg/refer-button-icon-heart.svg";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useReferralAmount } from "src/components/routes/Refer/hooks/useReferralAmount";
import { ReferralsSource } from "src/common/trackingEventAttributes";

const ReferButton = () => {
  const { sendEvent } = useSegment();
  const referralAmount = useReferralAmount();

  return (
    <Visibility visible={["none", null, null, "block"]}>
      <Box gutterH={1} gutterV={0.5} bg={Color.Transparent} borderRadius={3}>
        <LinkBuilder
          route={Route.Refer}
          onClick={() => {
            sendEvent(TrackingEvent.DASHBOARD_REFER_CTA_CLICKED, {
              source: ReferralsSource.REFER_BUTTON,
            });
          }}
        >
          {(result) => (
            <PlainLink {...result}>
              <Box
                layout="flex"
                flexDirection="row"
                alignItems={AlignItems.Center}
              >
                <Box
                  layout="flex"
                  flexDirection="row"
                  justifyContent={JustifyContent.Center}
                  alignItems={AlignItems.Center}
                  width="24px"
                  height="24px"
                  bg={Color.Sunrise_700}
                  borderRadius={2}
                >
                  <img
                    src={ReferButtonIconHeart}
                    alt=""
                    width={16}
                    height={16}
                  />
                </Box>
                <Space layout="inline" h={0.5} />
                <Text weight={FontWeight.SemiBold}>
                  <Trans id="refer.button-label">
                    Refer & get {referralAmount.incentive}
                  </Trans>
                </Text>
              </Box>
            </PlainLink>
          )}
        </LinkBuilder>
      </Box>
    </Visibility>
  );
};

export default ReferButton;
